import React from 'react';
import { Link, graphql } from 'gatsby'
import { Helmet } from "react-helmet"
import ReactMarkdown from 'react-markdown';
import Social from "../components/social.js"
import Layout from "../components/LayoutAlt"



const WorldToday = ({ data }) => {
    return (
        <Layout>

        <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Religious events around the globe. Religious liberty. Let us be watchful, be sober, be vigilant." />
        <title>ReaCH | World Today</title>
        <link rel="canonical" href="https://reachun.org/worldtoday" />
      </Helmet>

            <div className="sm:w-3/4 sm:ml-1/8 mt-20 mb-20">
            <section>
                <h1 className="font-playfairdisplay font-bold text-7xl text-blue-dark text-center mb-2">
                Global Report
                </h1>
                <p className="font-opensans text-gray-700 text-xl text-center tracking-widest mb-20">Our Religious Liberty and Global Events in Focus.</p>
            </section>
             
              <section className="grid xs:grid-cols-1 sm:grid-cols-2  xl:grid-cols-4">


               
                <div className="xs:px-4 sm:pr-4 order-2 md:order-2 lg:order-2">
                {data.allStrapiWorldtodays.edges.map(left =>(
                   <div key={left.node.id} className={left.node.l_panel ? "block" : "hidden"}>
                    <Link to={`/worldtoday/${left.node.slug}`} >
                  <div className="xs:pt-4 sm:pt-0 pb-4  text-gray-700 border-b border-gray-200 space-y-4">
                     <h1 className="font-playfairdisplay font-bold xl:text-xl text-gray-700 pt-2"><ReactMarkdown children={left.node.h_desc} /></h1>
                     <p className="font-opensans mt-2 text-gray-600"><ReactMarkdown children={left.node.b_desc} /></p>
                     <div className="font-opensans text-xs mt-2 text-gray-600 flex space-x-2">
                        <p className="font-bold"><ReactMarkdown children={left.node.author.name}/></p>
                        <p><ReactMarkdown children={left.node.date} /></p>
                        </div>
                  </div>
                    </Link>
                  </div>
                  ))}
                </div>
              

                <div className="col-span-1 sm:col-span-2 px-4 border-r border-l border-gray-200 order-1 xs:mb-4 sm:mb-12 lg:order-1 xl:order-2">
                {data.allStrapiWorldtodays.edges.map(center => (
                   <div key={center.node.id} className={center.node.featured ? "block" : "hidden"}>
                   <Link to={`/worldtoday/${center.node.slug}`}>
                   <img src={center.node.image.url} alt="Hero"/>
                   <div className="p-4 border-b border-gray-200">
                        <h1 className="font-playfairdisplay font-bold text-2xl text-gray-700 "><ReactMarkdown children={center.node.h_desc} /></h1>
                        <p className="font-opensans mt-2 text-gray-600"><ReactMarkdown children={center.node.b_desc } /></p>
                        <div className="font-opensans text-xs mt-2 text-gray-600 flex space-x-2">
                        <p className="font-bold"><ReactMarkdown children={center.node.author.name}/></p>
                        <p><ReactMarkdown children={center.node.date} /></p>
                        </div>
                   </div>
                   </Link>
                   </div>
                   ))}
                   {data.allStrapiWorldtodays.edges.map(center => (
                      <div className={center.node.c_panel ? "block" : "hidden"}>
                   <div className="p-4 border-b border-gray-200">
                       <Link to={`/worldtoday/${center.node.slug}`}>
                        <h1 className="font-playfairdisplay font-bold text-lg text-gray-700"><ReactMarkdown children={center.node.h_desc} /></h1>
                        <p className="font-opensans mt-2 text-gray-600 text-sm"><ReactMarkdown children={center.node.b_desc} /></p>
                        <div className="font-opensans text-xs mt-2 text-gray-600 flex space-x-2">
                        <p className="font-bold"><ReactMarkdown children={center.node.author.name}/></p>
                        <p><ReactMarkdown children={center.node.date} /></p>
                        </div>
                        </Link>
                   </div>
                   </div>
                   ))}
                
                </div>


             
                <div className="xs:px-4 sm:pr-4 order-3">
                {data.allStrapiWorldtodays.edges.map(right =>(
                   <div key="right.node.id" className={right.node.r_panel ? "block" : "hidden"}>
                      <Link to={`/worldtoday/${right.node.slug}`}>
                  <div className="xs:pt-4 sm:pt-0 pb-4  text-gray-700 border-b border-gray-200 space-y-4">
                     <h1 className="font-playfairdisplay font-bold xl:text-xl   text-gray-700 pt-2">
                        <ReactMarkdown children={right.node.h_desc} />
                     </h1>
                     <p className="font-opensans mt-2 text-gray-600">
                        <ReactMarkdown children={right.node.b_desc} />
                     </p>
                     <div className="font-opensans text-xs mt-2 text-gray-600 flex space-x-2">
                        <p className="font-bold"><ReactMarkdown children={right.node.author.name}/></p>
                        <p><ReactMarkdown children={right.node.date} /></p>
                        </div>
                  </div>
                  </Link>
                  </div>
                  ))}
                </div>
              </section>



              <div className="mt-20 xs:p-2 sm:p-0">
                <h1 className="font-montserrat font-bold text-xl text-gray-700">Older Posts</h1>
                <section className="grid sm:grid-cols-2 sm:gap-2 lg:grid-cols-3 xl:grid-cols-4 mt-5">
                {data.allStrapiWorldtodays.edges.map(report =>(
                  <div key={report.node.id} className={report.node.oldposts ? "block" : "hidden"}>
                        <div className="mt-5">
                        <Link to={`/worldtoday/${report.node.slug}`}>
                        <img src={report.node.image.url} alt="Hero" className="w-full 2xl:h-48 h-40 object-cover" />
                        <h1 className="font-playfairdisplay font-bold text-gray-700 text-lg mt-4">
                        <ReactMarkdown children={report.node.h_desc} />
                        </h1>
                      
                        <p className="font-opensans mt-2 text-gray-600 text-sm">
                        <ReactMarkdown children={report.node.b_desc} />
                        </p>

                        <div className="font-opensans text-xs mt-2 text-gray-600 flex space-x-2">
                        <p className="font-bold"><ReactMarkdown children={report.node.author.name}/></p>
                        <p><ReactMarkdown children={report.node.date} /></p>
                        </div>
                        </Link>
                   </div>
                        </div>
                ))} 
                </section>
              </div>
            </div>
            <Social />
        </Layout>
    )
}

export const query = graphql`
  {
    allStrapiWorldtodays(sort: {fields: date, order: DESC}) {
      edges {
        node {
           id
          title
          featured
          date(formatString: "LL")
          slug
          content
          image {
                url
          }
          b_desc
          h_desc
          c_panel
          l_panel
          r_panel
          oldposts
          author {
            name
            slug
            avatar {
              formats {
                thumbnail {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

export default WorldToday